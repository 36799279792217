import { BaseCompareProductsService } from 'services/compare-products/baseCompareProducts.service';
import { CompareProductsFactoryService } from 'services/compare-products/compareProductsFactory.service';
import { useUserStore } from 'store/user.store';
import { UserHelper } from 'utils/userHelper.util';

export function useCompareProducts(): void {
  const compareProductsService = CompareProductsFactoryService.createCompareProductsService();
  provide(BaseCompareProductsService.getServiceName(), compareProductsService);

  const userStore = useUserStore();

  if (process.server) {
    return;
  }

  watch(
    () => userStore.isUserLoggedIn,
    async (newIsUserLoggedIn, oldIsUserLoggedIn) => {
      if (newIsUserLoggedIn && !oldIsUserLoggedIn && !UserHelper.isSupplier) {
        await compareProductsService.loadCount();
      }
    },
    { immediate: true, flus: 'post' },
  );
}
