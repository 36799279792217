import { ECompareProductsType } from 'enums/services/compare-products/compareProductsType.enum';
import { BaseCompareProductsService } from './baseCompareProducts.service';
import { MpCompareProductsService } from './mpCompareProducts.service';

export class CompareProductsFactoryService {
  private static compareProductsMap = new Map<
    ECompareProductsType,
    new () => BaseCompareProductsService
  >([
    [ECompareProductsType.MP, MpCompareProductsService],
  ]);

  public static createCompareProductsService(
    type: ECompareProductsType = ECompareProductsType.MP,
  ): BaseCompareProductsService {
    const CompareProductsService = CompareProductsFactoryService.compareProductsMap.get(type);
    if (!CompareProductsService) {
      throw new Error(`Not found compare products service for type: ${type}`);
    }

    return new CompareProductsService();
  }
}
